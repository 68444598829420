import { makeStyles } from "@material-ui/core";
import { placemnet } from "../../.themes/common/Theme";
export const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: (props) => ({
        display: "flex",
        fontFamily: theme.typography.fontFamily,
        height: "100%",
        maxHeight: props.useMOBanner ? 194 : 146,
        boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 7%), 0px 1px 10px 0px rgb(0 0 0 / 6%)",
    }),
    toolbar: {
        display: "flex",
        flexDirection: "column",
        padding: 0,
        backgroundColor: theme?.header?.backgroundColor || "white",
        borderBottom: `1px solid ${theme.colorCard?.dividerLine || theme.navigation?.divider}`,
        ...theme.customStyling?.Header?.toolbar,
    },
    menuBarWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        height: 72,
        maxWidth: 1120,
        ...theme.customStyling?.Header?.menuBarWrapper,
    },
    searchBoxOpenInMobile: {
        paddingRight: "24px",
        paddingLeft: "24px",
        boxSizing: "border-box",
    },
    menuBar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        boxSizing: "border-box",
        paddingLeft: 12,
        paddingRight: 12,
    },
    menuButton: {
        marginRight: theme.spacing(0),
        marginLeft: theme.spacing(0),
        color: theme.colorCard?.primaryHover || theme.palette?.primary?.dark,
    },
    hide: {
        display: "none",
    },
    divider: {
        width: "100%",
        backgroundColor: theme.header?.dividerColor || theme.colorCard?.dividerColor,
        height: theme.header?.dividerHeight,
        ...theme.customStyling?.Header?.divider,
    },
    drawerPaper: (props) => ({
        width: "100%",
        backgroundColor: theme.colorCard?.background || theme.palette?.background?.default,
        marginTop: props.useMOBanner ? 194 : 146,
        maxWidth: "100%",
        maxHeight: props.useMOBanner ? "calc(100% - 190px)" : "calc(100% - 142px)",
    }),
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 56,
        ...theme.customStyling?.Header?.drawerHeader,
    },
    linksDesktop: {
        display: "flex",
        flexDirection: "row",
    },
    links: {
        paddingLeft: 12,
        paddingRight: 12,
        borderRight: `1px solid ${theme.colorCard?.dividerLine || theme.navigation?.divider}`,
        alignSelf: "center",
        "&:last-child": {
            border: "none",
            paddingRight: 0,
        },
        ...theme.customStyling?.Header?.links,
    },
    link: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 400,
        textDecoration: "none",
        color: theme.header?.navigationLinkColor || theme.colorCard?.primary,
        "&:hover": {
            textDecoration: "underline",
            ...theme.customStyling?.Header?.link?.hover,
        },
        ...theme.customStyling?.Header?.link,
    },
    icon: {
        height: 24,
        width: 24,
    },
    loginIcon: (props) => ({
        backgroundImage: `url(${props.loginIcon})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
        backgroundPositionY: "center",
        height: 24,
        width: 24,
    }),
    activeLink: {
        color: theme.colorCard?.primary || theme.palette?.primary?.main,
        fontWeight: 600,
    },
    headerBlock: {
        boxSizing: "border-box",
        paddingLeft: 24,
        paddingRight: 24,
        width: "100%",
        flexWrap: "wrap",
        [theme.breakpoints.up("md")]: {
            flexDirection: theme.header?.logoPlacement === placemnet.RIGHT ? "row-reverse" : "row",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: 1120,
            alignSelf: "center",
            height: 72,
        },
        alignSelf: theme.header?.logoPlacement === placemnet.CENTER
            ? "center"
            : "self-start",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 72,
        ...theme.customStyling?.Header?.headerBlock,
    },
    menuButtonText: {
        paddingLeft: 8,
        ...theme.customStyling?.Header?.menuButtonText,
    },
    logoWrapper: {
        display: "flex",
        alignItems: "center",
        ...theme.customStyling?.Header?.logoWrapper,
    },
    logo: {
        height: 40,
        width: 124,
        "&:hover": {
            backgroundColor: "transparent",
        },
        [theme.breakpoints.up("sm")]: {
            width: 180,
        },
    },
    //for comp with VW
    //for VW adjustment: "calc(50% + 90px)"
    //for fully filled (180x40) logo adjust position by width: "calc(50% + 90px)"
    logoCenteredWrapper: {
        width: "calc(50% + 62px)",
        display: "flex",
        justifyContent: "end",
        [theme.breakpoints.up("sm")]: {
            width: "calc(50% + 90px)",
        },
    },
    logoCentered: {
        maxHeight: 40,
        width: 124,
        "&:hover": {
            backgroundColor: "transparent",
        },
        [theme.breakpoints.up("sm")]: {
            width: 180,
        },
    },
    rightIconAndLinkWrapper: {
        display: "flex",
        alignItems: "center",
        gap: 24,
    },
    additionalLogoWrapper: {
        display: "flex",
        alignItems: "center",
        ...theme.customStyling?.Header?.additionalLogoWrapper,
    },
    additionalLogo: {
        maxHeight: 40,
        width: 124,
        "&:hover": {
            backgroundColor: "transparent",
        },
        [theme.breakpoints.up("sm")]: {
            width: 180,
        },
    },
    hiddenSeoHeader: {
        display: "none",
    },
}));
